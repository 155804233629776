import React, { useEffect, useState } from 'react';
import { Order } from '../../context/Order';
import useAxios from '../../services/hooks/axios-hook';
import { toast } from 'react-toastify';

import './style.css'
interface PaymentValue{
  amount: number;
  amountCurrency: string;
  paymentId: string;
  payed:boolean
}
const PagarmeButton: React.FC<PaymentValue> = ({amount,amountCurrency, paymentId, payed }) => {
  const {post,put} = useAxios()
  const order = window.localStorage.getItem('orderData') 
  const auth = JSON.parse(window.localStorage.getItem('auth') as string)
  const orderData: Order = order && (JSON.parse(order));
  const openCheckout = ()=>{
    window.localStorage.setItem('orderData',JSON.stringify({...orderData,paymentActiveValue: amount}))
    let checkout = new (window as any).PagarMeCheckout.Checkout({
        encryption_key: process.env.REACT_APP_SECRETAPI_KEY,
        success: async function(data: any) {
          if(data.token){
          toast.success('Autorização de pagamento realizada com sucesso');
          const promises = await Promise.all([
            await post('payments/capture',{payment_value: amount, payment: data, order: orderData},auth.access_token),
            await put(`payments/${paymentId}`,{statusPayment:true})
          ])
          if(promises[0]&& promises[0].status==200){
            toast.success('Pagamento Capturado Com sucesso')
          }
          if(promises[1]&& promises[1].status==200){
            window.localStorage.setItem('orderData', JSON.stringify({...orderData, payments:promises[1].data.payments}))
            toast.success('Pagamento Capturado Com sucesso')
          }
        }
        window.location.reload()
        },
        error: function(err: any) {
          alert('Por favor entre em contato com o seu banco para liberar o pagamento, não tente  realizar novamente o pagamento')
          if(err){
            toast.error('Erro no pagamento, entre em contato com seu banco para que possamos reprocessar seu pagamento',{autoClose: 10000})
          }
       
        },
        close: function() {
          toast.error("você fechou a janela sem realizar o pagamento");

        }
      });

      checkout.open({
        amount: amount*100,
        createToken: 'true',
        paymentMethods: 'credit_card',
        customerData: false,
        maxInstallments: 9,
        defaultInstallments: 1,
        customer: {
          external_id: orderData?.buyer.cpf,
          name: orderData?.buyer.name,
          type: 'individual',
          country: 'br',
          email: orderData?.buyer.email,
          documents: [
            {
              type: 'cpf',
              number: orderData?.buyer.cpf,
            },
          ],
          phone_numbers: [`+55${orderData?.buyer.phone}`],
          birthday: '1985-01-01',
        },
        billing: {
          name: orderData?.buyer.name,
          address: {
            country: 'br',
            state: orderData?.buyer.address.state,
            city: orderData?.buyer.address.city,
            neighborhood: orderData?.buyer.address.neighborhood,
            street: orderData?.buyer.address.street,
            street_number: orderData?.buyer.address.streetNumber,
            zipcode: orderData?.buyer.address.zipcode
          }
        },
          items: [
            {
              id: '1',
              title: 'Viagem para Nasa',
              unit_price: amount*100,
              quantity: 1,
              tangible: false
            },
          ]
      });    
}

  const [price, setPrice ] = useState<Number>(0)
  useEffect(() => {
    //Import  Pagarme Checkout Lib
    const script = document.createElement('script');
    script.src = "https://assets.pagar.me/checkout/1.1.0/checkout.js";
    document.getElementsByTagName('head')[0].appendChild(script);


    
    
  },[])
        return(
          <>
          {payed?
          (<button className="buttonPagarme no-click " onClick={openCheckout}>Valor pago {amountCurrency}</button>):
          (<button   className="buttonPagarme" onClick={openCheckout}>Pagar {amountCurrency}</button>)}
      </> 
       );
}

export default PagarmeButton;