import React, { useEffect } from 'react';
import { Container, Content, Logo, ContainerForm } from './styles';
import logoImg from '../../assets/images/nasa.png';
import logoyoung from '../../assets/images/logoyoung.png';
import ModalCustom from '../../components/modal';
import SignupAddressForm from '../../components/address';
import SignupForm from '../../components/signupForm';
import Quiz from '../quiz';
import { useFormComplete } from '../../hooks/form';
import { useAuth } from '../../hooks/auth/auth-hook';
import { useIsOpenModal } from '../../hooks/modal';
import { disableBodyScroll } from 'body-scroll-lock';



const SignupPage: React.FC = () => {

    const { isOpenModal, setIsOpenModal } = useIsOpenModal();
	const { isCompleteForms } = useFormComplete();
	const {auth,setAuth} = useAuth();
	const currentElement = document.querySelector<any>('#root');
	useEffect(() => {
		window.localStorage.setItem('auth',JSON.stringify({}))
        window.localStorage.setItem('orderData',JSON.stringify({}))
        disableBodyScroll(currentElement)
    }, [])

    return(
		<Container>
			<Logo />
			<ContainerForm>
				<Content>
					<img src={logoyoung} alt="Nasa" width="75px" style={{ marginLeft: -300 }} />
					<img src={logoImg} alt="Nasa" width="150px" style={{ marginBottom: 30 }} />
					{(isCompleteForms.showQuiz && !auth?.access_token) && <Quiz />} 
					{isOpenModal ? (
						<ModalCustom isOpenModalProp={true} onCloseModal={() => console.log('Modal fechado')}>
							{isCompleteForms.isCompleteFormSignup ? <SignupAddressForm /> : <SignupForm />}
						</ModalCustom>
					) : null}
				</Content>
        
			</ContainerForm>
		</Container>
  )
}

export default SignupPage;