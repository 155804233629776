import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FiLock, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import Button from '../button';
import Input from '../input';


import { Container, ErrorSpan } from './styles';
import { useFormComplete } from '../../hooks/form';
import { Order } from '../../context/Order';
import { checkCpf } from '../../services/check-cpf-validation';
import useAxios from '../../services/hooks/axios-hook';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

interface BuyerInputs{
    name: string;
    cpf: string;
    phone: string;
    email: string;
}
const SignupForm: React.FC = () => {

  const{isCompleteForms,setIsCompleteForms} = useFormComplete()
  const {get,post} = useAxios()
  const[orderData, setorderData] = useState<Order>()

  const { register, handleSubmit, errors,reset } = useForm<BuyerInputs>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
  });

  useEffect(()=>{

      const orderDataLocal = JSON.parse(window.localStorage.getItem('orderData')as string)
      if(orderDataLocal){
        setorderData(orderDataLocal);
      }
  },[])

   const  onSubmit: SubmitHandler<BuyerInputs> = async (data: BuyerInputs, e:any) =>{
    e.preventDefault();
    const isValidCpf= checkCpf(data.cpf)
    if(!isValidCpf){
      toast.error('CPF não está valido')
      reset({cpf:''})
    }else{
      const buyerExist: AxiosResponse  = await post(`buyers/${data.cpf}`,{})
      if(buyerExist){
        toast.error('Comprador já cadastrado')
        reset()
      }else{
        setIsCompleteForms({isCompleteFormSignup:true});
      window.localStorage.setItem('orderData',JSON.stringify({...orderData,buyer:data}))
      }
      
    }
   
  }
    const onError = (_erros: any, e: any)=>{
      console.log(_erros);
    }
  return ( 
    <Container> 
     <form onSubmit={handleSubmit(onSubmit,onError)}>
       <h1>Cadastro</h1>
       <Input
         icon={FiUser}
         name="name"
         ref={register({required: true})}
         type="text"
         placeholder="Digite seu Nome"
       />
       {errors.name&& <ErrorSpan>* Campo Obrigatório</ErrorSpan>}
       <Input 
       icon={FiPhone} 
       name="phone" 
       ref={register({required: true, minLength: 11, maxLength:11})}
       placeholder="Digite Seu Telefone" 
       />
       {errors.phone&& <ErrorSpan>* Campo Obrigatório, veja se não esqueceu o código do estado</ErrorSpan>}
       
       {errors.phone && errors.phone.type === "minLength" && <ErrorSpan>Digite os 9 números e inclua o ddd no início</ErrorSpan> }
        {errors.phone && errors.phone.type === "maxLength" && <ErrorSpan>Digite apenas 11 dígitos do telefone</ErrorSpan> }

       <Input
         icon={FiLock}
         name="cpf"
         type="text"
         ref={register({required: true, minLength: 11, maxLength:11})}
         placeholder="Digite seu CPF"
       />
         {errors.cpf&& <ErrorSpan>* Campo Obrigatório</ErrorSpan>}
         {errors.cpf && errors.cpf.type === "minLength" && <ErrorSpan>Digite os 11 dígitos do cpf sem espaços ou pontos</ErrorSpan> }
        {errors.cpf && errors.cpf.type === "maxLength" && <ErrorSpan>Digite apenas os 11 dígitos do cpf</ErrorSpan> }

        <Input 
         icon={FiMail}
         name="email" 
         ref={register({required: true})}
         placeholder="Digite Seu E-mail" 
         />
          {errors.email&& <ErrorSpan>* Campo Obrigatório</ErrorSpan>}
     
       <Button type="submit" >Continuar cadastro</Button>
       <a href="https://api.whatsapp.com/send?phone=+558281446138" target="_blank"><span style={{color: '#fff'}}>Entrar em Contato</span></a>
     </form>

</Container>
  );
}

export default SignupForm;

