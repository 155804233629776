import axios from 'axios';
import { disableBodyScroll } from 'body-scroll-lock';
import React, { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/button';
import Input from '../../components/input';
import ModalCustom from '../../components/modal';
import { useAuth } from '../../hooks/auth/auth-hook';

import useAxios from '../../services/hooks/axios-hook';

interface CheckSignup{
    cpf: string;
    password:string;
}
const CheckSignup: React.FC = () => {

    const [cpfCheckSignup, setCpfCheckSignup] = useState('')
    const{auth, setAuth} = useAuth();
    const navigate = useNavigate()
    const {post, data, errors} = useAxios();
    const currentElement = document.querySelector<any>('#root');

    useEffect(()=>{
        disableBodyScroll(currentElement)
        
    })

    const setData= (accessToken: string)=>{
        setAuth({...auth,access_token:accessToken})
        window.localStorage.setItem('auth',JSON.stringify({access_token:accessToken}))
        navigate('/payment');
    }

    const redirectToSignUp = ()=>{
        toast.info('Você não está cadastrado ainda')
        navigate('/signup')
    }

    const onClickCheckSignUp = async ()=>{
        const signupCheckData: CheckSignup={cpf: cpfCheckSignup, password:'12345'} 
        const dataSignUp = await post('/checksignup',signupCheckData)
        window.localStorage.setItem('auth',JSON.stringify({...dataSignUp}))
        dataSignUp? setData(dataSignUp?.access_token)
           : redirectToSignUp()

        
        
    }

  return (
        <ModalCustom  isOpenModalProp={true}  onCloseModal={()=>{}}>
            <div>
                <Input style={{ textAlign:'center'}}
                 placeholder="Digite seu cpf" 
                 onChange={(e)=>setCpfCheckSignup(e.target.value)}/>
                <Button onClick={onClickCheckSignUp}>Checar cadastro</Button>
            </div>

        </ModalCustom>

  );
}

export default CheckSignup;