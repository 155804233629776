import styled from 'styled-components';
import { darken, shade } from 'polished';

export const Container = styled.div`
  display: flex;
  h1 {
    color: rgb(47, 49, 141);
    background-color: rgba(255, 255, 255, 0.5);
    padding: 3px;
    border: 0.05em solid ${darken(0.3, '#7159c1')};
    border-radius: 10px;
  }
  form {
    flex: 1;
    margin: 30px 0;
    text-align: center;

    a {
      color: #fff;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }
`;
export const ErrorSpan = styled.span`
  display: inline-block;
  padding-top: 4px;
  padding-bottom: 5px;
  width: 90%;
  font-size: 0.8rem;
  color: #fb6f91;
  align-self: center;
  font-weight: bold;
`;
