import React, { useCallback, useEffect, useRef, useState }   from 'react';
import {partners} from '../../data/departments/department-partners'
import { Select } from 'react-functional-select';
// import './styles.css'
import { useOrder } from '../../hooks/order/order-hook';
import { useFormComplete } from '../../hooks/form';
import { Card, CardBody, CardHeader, Container, SelectContainer } from './helpers-styled';

const colourStyles = {
    control: (styles:any) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }:any) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? 'red' : 'blue',
        color: '#FFF',
        cursor: isDisabled ? 'not-allowed' : 'default',

      };
    },
  };
const  SingleSelect = ()=>{

  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isClearable, setIsClearable] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const { isCompleteForms, setIsCompleteForms } = useFormComplete();


  const getOptionValue = useCallback((option: any): number => option.value, []);
  const onOptionChange = useCallback(
    (option: any | null): void => {
      setIsCompleteForms({...isCompleteForms, partner:option.value})
      const order = JSON.parse(window.localStorage.getItem('orderData')as string)
      window.localStorage.setItem('orderData',JSON.stringify({...order, partner: option.value}))
      setSelectedOption(option)}, []);
  const getOptionLabel = useCallback((option: any): string => `${option.label}`, []);

  useEffect(() => {
    isDisabled && setIsInvalid(false);
  }, [isDisabled]);

      return (
        <Container>
          <Card>

              <Select
           isInvalid={isInvalid}
           options={partners}
           isDisabled={isDisabled}
           isClearable={isClearable}
           onOptionChange={onOptionChange}
           getOptionValue={getOptionValue}
           getOptionLabel={getOptionLabel}
           placeholder="Pesquisar"

          />

        </Card>
    </Container>
      );
  }

  export default SingleSelect;