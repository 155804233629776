
import useAxios from '../../services/hooks/axios-hook'

export  function useApiSignup() {
    const {post} = useAxios()
    let authCheck =false;
    const signup =async()=>{
        const localSignupData = JSON.parse(window.localStorage.getItem('orderData')as string)

        if(localSignupData){
            const orderData = JSON.parse(window.localStorage.getItem('orderData') as string)
            const dataFormatedToCreateOrder={...orderData, payments:[{amount: orderData.total, statusPayment:false}], payed: false}
            const createOrderData = await post('orders',dataFormatedToCreateOrder)
            if(createOrderData){
                const {access_token} = await post('checksignup',{cpf: createOrderData.buyer.cpf,password:'12345'})
                window.localStorage.setItem('auth',JSON.stringify({access_token:access_token}))
                window.localStorage.setItem('orderData',JSON.stringify(createOrderData) as string)
                authCheck=true;
            }      
        }
        return authCheck;
    }
    
    return {signup}
            
}
