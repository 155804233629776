import styled from 'styled-components';

export const Container = styled.div`


    margin-top: 200px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;

`;
