import React, { useEffect, useState } from 'react';
import GlobalStyle from './styles/global';
import {
  ModalContext,
  FormContext,
  AuthContext,

} from './context';
import { BrowserRouter, Router } from 'react-router-dom';
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import CustomRoutes from './routes';
function App() {
  const [auth, setAuth] = useState();
  const [order, setOrder] = useState({
    buyer: {
      id: '',
      cpf: '',
      name: '',
      phone: '',
      email: '',
      address: {
        city: '',
        country: 'Brasil',
        neighborhood: '',
        state: '',
        street: '',
        streetNumber: '',
        zipcode: '',
      },
    },
    payments: [],
    id: '',
    payed: false,
    total: 0,
    valuePayed: 0,
    plan: '',
    partner: '',
    paymentActiveValue: 0,
  });
  const[paymentValue, setPaymentValue] = useState(0)
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCompleteForms, setIsCompleteForms] = useState({
    isCompleteFormSignup: false,
    isCompleteFormAddress: false,
    showQuiz: true,
    isCreditCardOptionShow: false,
    totalEqualsOrder: false,
    moreThanOndeCreditCard: false,
    partner: ''
  });

  return (
    <div>
      <BrowserRouter>
      
        <AuthContext.Provider value={{ auth, setAuth }}>
            <FormContext.Provider
              value={{ isCompleteForms, setIsCompleteForms }}
            >
                <ModalContext.Provider value={{ isOpenModal, setIsOpenModal }}>

                <CustomRoutes />
                <ToastContainer autoClose={8000} />
                <GlobalStyle /> 
               
              </ModalContext.Provider>
            </FormContext.Provider>
        </AuthContext.Provider>
      </BrowserRouter>
    </div>
  );
}
export default App;
