import React, { useState } from 'react';

import {  Container,Description } from './styles';

interface IQuestion{
    description: string;
    children: React.ReactNode;
}

const Question: React.FC<IQuestion> = ({description, children}, ) => {

  return (
        <Container>
            <Description>{description}</Description>
        </Container>
        

  );
}

export default Question;