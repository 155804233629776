import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  flex-wrap: wrap;
  padding-bottom: 40px;
  justify-content: center;
  align-content: center;
`;

export const Answer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  list-style: none;
  height: 50px;
  margin-bottom: 30px;
`;
