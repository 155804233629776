import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;

  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const Description = styled.label`
  color: #a691ec;
  font-size: 20px;
`;
