import { useContext } from 'react';
import { AuthContext } from '../../context';



export function useAuth() {
  const currentContext = useContext<any>(AuthContext);
  const { auth, setAuth } = currentContext;
  return { auth, setAuth };
}
