import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CheckSignup from '../pages/check-signup';
import Payment from '../pages/payment';
import SignupPage from '../pages/signup-page';

const  CustomRoutes= () =>
        <Routes>
          <Route path="/payment" element={<Payment/>}/>
          <Route path="/" element={<CheckSignup/>} />
          <Route path="/signup"  element={<SignupPage/>}/>
        </Routes>



export default CustomRoutes;