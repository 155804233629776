import { createGlobalStyle } from 'styled-components';
// import 'react-toastify/dist/ReactToastify.css';
import signInImage from '../assets/images/logonasa.jpg';

export default createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');

    *{
        margin:0;
        padding: 0;
        outline: 0;
        box-sizing: border-box
    }

    *:focus{
        outline: 0;
    }

    html, body, #root {
        height: 100vh;
    }

    

    body{
        background: rgb(244,245,246);
        color: #FFF;
        -webkit-font-smoothing: antialiased;
    }

    body::after{
        background-repeat: no-repeat;
        background: #191920 url(${signInImage}) no-repeat fixed;
        background-attachment: fixed;
        background-position: center;
        content: '';
        position: absolute;
        z-index: -1;
        opacity: 0.9;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    body, input, button, textarea {
        font-family: 'Roboto Slab', serif;
        font-size: 16px;
    }

    a{
        text-decoration: none;
    }

    ul{
        list-style: none;
    }

    button{
        cursor: pointer
    }

    h1, h2, h3, h4, h5, h6 strong{
        font-weight: 400;
    }



`;
