import React from 'react';
import { Order, OrderState } from './Order';
export interface IAuth{
    auth:any
    setAuth: React.Dispatch<React.SetStateAction<any>>;

}
const ModalContext = React.createContext({})

const FormContext = React.createContext({})


const OrderContext = React.createContext({})
const AuthContext = React.createContext<IAuth>({auth:{access_token:''}, setAuth:()=>{}});

export  {OrderContext, ModalContext, FormContext, AuthContext};

