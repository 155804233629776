import React, { useEffect, useState } from 'react';
import Question from '../../components/question';

import { Answer, Container } from './styles';
import {questions} from './questions.json'  
import Button from '../../components/button';
import { useOrder } from '../../hooks/order/order-hook';
import { useIsOpenModal } from '../../hooks/modal';
import { useFormComplete } from '../../hooks/form';
import { disableBodyScroll } from 'body-scroll-lock';
interface IQuestionQuiz{
    description:string;
    id: string;
    value: string;
    isActive: boolean;
    price: Number;

    
}
const Quiz: React.FC = () => {

    const [auth, setAuth] = useState<Boolean>(false)
    const{isOpenModal, setIsOpenModal} = useIsOpenModal()
    const [quiz, setQuiz] = useState<IQuestionQuiz[]>([])
    const {order, setOrder} = useOrder()
    const{isCompleteForms, setIsCompleteForms} = useFormComplete()
    const currentElement = document.querySelector<any>('#root');

    useEffect(() => {
        const questionsEnable = [...questions, {...questions[0], isActive: true}]
        setQuiz(questionsEnable)

        const dataAuth = JSON.parse(window.localStorage.getItem('auth')as string)
        if(dataAuth && dataAuth.access_token){
            setAuth(true);
        }
       
        disableBodyScroll(currentElement)
    }, [])
    
    const checkAnswer: Function = (dataQuestion: IQuestionQuiz, e:any)=>{
        if(e.target.value==='yes'){
            window.localStorage.setItem('orderData',JSON.stringify({...order, total: dataQuestion.price, plan:dataQuestion.value }))
            setIsOpenModal(true);
            setIsCompleteForms({showQuiz:false})

        }
        if(e.target.value === 'no'){
            const quizInactive = quiz.filter(item=>item.value!==dataQuestion.value)
            if(quizInactive.length>0){
                quizInactive[0].isActive=true
                setQuiz(quizInactive)
            }
            if(quizInactive.length===0){
              window.localStorage.setItem('orderData',JSON.stringify({...order, total: 18480, plan:'sem-vinculo' }))
              setIsOpenModal(true);
              setIsCompleteForms({showQuiz:false})

            }
        }
}

  return( 
  <Container>
        {
        quiz.map(question=>
                {
                if(question.isActive){
                   return (
                       <>
                       <Question description={question.description} 
                       key={question.id}>
                       </Question>
                       <Answer>
                           <Button value={'yes'} onClick={(e)=>checkAnswer(question, e)}>Sim</Button>
                           <Button value={'no'}   onClick={(e)=>checkAnswer(question, e)}>Não</Button>
                       </Answer>
                       </>
                   )
                }
                
                }
               )}
  </Container>)
}

export default Quiz;