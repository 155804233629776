import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { FiCreditCard } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Order } from '../../context/Order';
import { useFormComplete } from '../../hooks/form';
import { useOrder } from '../../hooks/order/order-hook';
import useAxios from '../../services/hooks/axios-hook';
import Button from '../button';
import Input from '../input';
export interface PaymentCreditCard{
    id:string;
    amount: number;
    statusPayment: boolean;
}
interface ICreditCardPayments{
    amountCreditCardPayments: number
}
const CreditCardOptions: React.FC<ICreditCardPayments> = ({amountCreditCardPayments}: any) => {

    const [firstCreditCard, setFirstCreditCard] = useState('')
    const [secondCreditCard, setSecondCreditCard] = useState('')
    const [thirdCreditCard, setThirdCreditCard] = useState('')
	const { isCompleteForms, setIsCompleteForms } = useFormComplete();
    const [orderData, setOrderData] = useState<Order>();
    const {order,setOrder} = useOrder()
    const{put} = useAxios()

    const [amoutPayments, setAmoutPayments] = useState(2)
    useEffect(() => {
        const dataordeerLocal= JSON.parse(window.localStorage.getItem('orderData') as string)
        if(dataordeerLocal){
            setOrderData(dataordeerLocal);
        }
        setAmoutPayments(amountCreditCardPayments)

    }, [])

    const paymentsFormated = ()=>{
        const paymentsDataToFormat= [{amount: Number(firstCreditCard)},
            {amount: Number(secondCreditCard)}, {amount: Number(thirdCreditCard)}];
        return paymentsDataToFormat.filter(payment=>{
            if(payment.amount!==0){
                return Object.assign(payment, {statusPayment: false})
            }
        })
    }
    const  handleSubmit = async (data:any)=>{
        data.preventDefault()
        const totalOptionsValue = Number(firstCreditCard)+Number(secondCreditCard)+Number(thirdCreditCard)
        if(Number(orderData?.total)===totalOptionsValue){
            const token=  JSON.parse(window.localStorage.getItem('auth') as string)
            const {id}=JSON.parse(window.localStorage.getItem('orderData') as string)
            const dataUpdatePayments=paymentsFormated()
            const sendPaymentsUpdate = await put(`orders/${id}`,{payments:dataUpdatePayments}, token.access_token ) 
            if(sendPaymentsUpdate.status===200){
                const {payments} = sendPaymentsUpdate.data
                window.localStorage.setItem('orderData',JSON.stringify({payments:[...payments]}))
                setIsCompleteForms({...isCompleteForms,isCreditCardOptionShow:false, totalEqualsOrder:true})
                window.location.reload()
            }else{
                window.localStorage.setItem('orderData',JSON.stringify({...orderData,payments:[dataUpdatePayments]}))
                setIsCompleteForms({...isCompleteForms,isCreditCardOptionShow:false, totalEqualsOrder:true})
                window.location.reload()
            }
            //   setIsCompleteForms({...isCompleteForms,isCreditCardOptionShow:false, totalEqualsOrder:true})
            
        }else{
            toast.success('Valores digitados não somam o valor total da compra!')
            setIsCompleteForms({...isCompleteForms,isCreditCardOptionShow:true, totalEqualsOrder:false})

        }
    }

    const handleChange = (e: any)=>{
        const paymentValue= e.target.value
        if(paymentValue>0){
            switch (e.target.name) {
                case 'first':
                    setFirstCreditCard(paymentValue) 
                    break;
                case'second':
                    setSecondCreditCard(paymentValue)
                    break;
                case'third':
                    setThirdCreditCard(paymentValue)
                    break;
            }
        }
     
    }


  return (
        <form onSubmit={handleSubmit}>
        {amoutPayments===2?(
            <>
            <Input 
            icon={FiCreditCard}
            type="text"
            name="first"
            onChange={(e)=>handleChange(e)}
            placeholder="Primeiro Cartão(não inclua centávos)"
            />
             <Input 
            icon={FiCreditCard}
            type="text"
            name="second"
            onChange={(e)=>handleChange(e)}
            placeholder="Segundo Cartão(não inclua centávos)"
            />
            </>
        ):(
             <>
            <Input 
            icon={FiCreditCard}
            type="text"
            onChange={(e)=>handleChange(e)}
            placeholder="Primeiro Cartão(não inclua centávos)"
            name="first"
            />
            <Input 
            icon={FiCreditCard}
            value={secondCreditCard} 
            type="text"
            name="second"
            onChange={(e)=>handleChange(e)}
            placeholder="Segundo Cartão(não inclua centávos)"
            />
             <Input 
            icon={FiCreditCard}
            value={thirdCreditCard} 
            type="text"
            name="third"
            onChange={(e)=>handleChange(e)}
            placeholder="Terceiro Cartão(não inclua centávos)"
            />

            </>
        )}
        <Button type="submit">Confirmar</Button>
        </form>


  );
}

export default CreditCardOptions;