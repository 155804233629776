import { darken, shade } from 'polished';
import styled from 'styled-components';
import { ButtonProps } from './types';

export const Container = styled.button<ButtonProps>`
  display: flex;
  background: #7159c1;
  border-radius: 10px;
  height: 56px;
  border: 0;
  padding: 0 16px;
  color: #fff;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;
  justify-content: center;
  align-content: center;
  align-items: center;

  &:hover {
    background: ${darken(0.3, '#7159c1')};
  }
`;
