import { useState } from 'react';
import httpClient from '../http-client-axios';
export default function useAxios() {
  const [data, setData] = useState<any>(null);
  const [errors, setErrors] = useState<any>(null);
  
  const post = async (additionalPath: string, dataParams: any, token?:string) => {
    try {
      if(token){
        const dataReturned = await httpClient
        .getInstance()
        .post(additionalPath, dataParams,{headers:{
          Authorization: `Bearer ${token}`
        }});
      return dataReturned;
      }

      const dataReturned = await httpClient
        .getInstance()
        .post(additionalPath, dataParams);
      return dataReturned;
    } catch (error: any) {
      setErrors(error);
    }
  };
  const get = async (additionalPath: string,token:string) => {
    try {
      const dataReturned = await httpClient.getInstance().get(additionalPath,{
        headers:{'Authorization':`Bearer ${token}`}
      });
      setData(dataReturned);
      return dataReturned;
     
    } catch (error: any) {
      console.log(error)
      setErrors(error);
    }
  };
  const put = async (additionalPath: string, dataParams: any, token?:string) => {
    
    try {
      if(token){
        const dataReturned = await httpClient
        .getInstance()
        .put(additionalPath, dataParams,{headers:{
          Authorization: `Bearer ${token}`
        }});
      return dataReturned;
      }
      const dataReturned = await httpClient
        .getInstance()
        .put(additionalPath, dataParams);
      return dataReturned;
    } catch (error: any) {
      setErrors(error);
    }
  };

  

  return { data, errors, post, get, put };
}
