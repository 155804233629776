import styled from 'styled-components';
import { darken, shade } from 'polished';
import signInImage from '../../assets/images/logonasa.jpg';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  margin: auto;
  width: 50%;
  margin-top: 2em;
  label {
    color: rgb(47, 49, 141);
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px;
    border: 0.05em solid ${darken(0.3, '#7159c1')};
    border-radius: 10px;
    font-weight: bold;
    display: inline-block;
    span > {
      font-size: 20px;
      color: rgb(47, 49, 141);
    }
  }
`;

export const ContainerForm = styled.div`
  flex: 1;
  background: rgba(0, 0, 0, 0.4);
  margin: auto;
  width: 50%;
  fill-opacity: 0.7;
  position: absolute;
  border-radius: 10px;
  border: 0.1em solid ${darken(0.6, '#7159c1')};
  padding: 30px;
  width: 30em;
`;

export const Logo = styled.div`
  width: 133px;
  height: 90px;
  margin: 20px 338px 0 0;
`;

export const Options = styled.div`
  border-radius: 8px;
  border: 0.1em solid #fff;
  width: 340px;
  > input {
    color: #000;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 450px;
  > a {
    color: #fff;
    display: block;
    margin-top: 16px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;
    svg {
      margin-right: 20px;
    }
    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }
`;
export const Quiz = styled.div`
  display: block;
  padding-bottom: 40px;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

export const QuizAnswer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  list-style: none;
  height: 50px;
`;
export const QuizQuestion = styled.div`
  flex: 1;
  text-align: center;
`;
export const Selection = styled.select`
  border-radius: 4px;
  border: 2px solid #000;
  size: 6;
  color: #000;
  > option {
    font-size: 16px;
    color: #000;
  }
`;

export const InfoBuyer = styled.div`
  flex: 1;
  width: 100%;
  align-items: left;
  justify-content: left;
  align-content: left;
  margin-bottom: 1.4em;
`;
