import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {FaStreetView,FaWarehouse} from 'react-icons/fa'
import {BsGeoAlt, BsCodeSlash} from 'react-icons/bs'
import {GiModernCity} from 'react-icons/gi'
import {AiOutlineFieldNumber} from 'react-icons/ai'
import Button from '../button';
import Input from '../input';

import { Container, ErrorSpan } from './styles';
import { Address } from '../../context/Address';
import { useIsOpenModal } from '../../hooks/modal';
import { useFormComplete } from '../../hooks/form';
import { useApiSignup } from '../../hooks/signup';
import {useNavigate} from 'react-router-dom';
import { Order } from '../../context/Order';
import { useAuth } from '../../hooks/auth/auth-hook';

const SignupAddressForm: React.FC = () => {
  
  const { register, handleSubmit, errors } = useForm<Address>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
});

const [orderData, setOrderData] = useState<Order>();


useEffect(()=>{
const dataordeerLocal= JSON.parse(window.localStorage.getItem('orderData') as string)
  if(dataordeerLocal) {
    setOrderData(dataordeerLocal);
  }
}
,[])

  const {setIsOpenModal} = useIsOpenModal();
  const{isCompleteForms, setIsCompleteForms } = useFormComplete()
  const {signup} = useApiSignup()
  const {auth} = useAuth()

  const navigate = useNavigate();
  async function submitForm(data: Address, e:any){
      // e.preventDefault();
      
      const addressObj ={...orderData?.buyer,address:data}
      window.localStorage.setItem('orderData',JSON.stringify({...orderData,buyer:addressObj}) )
      if(await signup()){
        setIsOpenModal(false);
        setIsCompleteForms({isCompleteForms:{...isCompleteForms,isCompleteFormAddress:true}})
        navigate('/payment')
      }
     

    

  }

  function onError(data: any,e: any){
    console.log('erros', data);
}

  return ( 
    <Container> 
     <form onSubmit={handleSubmit(submitForm,onError)}>
       <h1>Cadastro de Endereço</h1>
       <div style={{marginBottom: '1em'}}> <label >(* todos os campos são obrigatórios)</label></div>
       
       <Input
         icon={FaStreetView}
         name="street"
         ref={register({required: true})}
         type="text"
         placeholder="Digite sua rua"
       />
        {errors.street && <ErrorSpan>* Campo Obrigatório</ErrorSpan>}
       <Input 
       icon={BsGeoAlt} 
       name="state" 
       ref={register({required: true})}
       placeholder="Digite seu estado" 
       />
      {errors.state && <ErrorSpan>* Campo Obrigatório</ErrorSpan>}

       <Input
         icon={FaWarehouse}
         name="neighborhood"
         type="text"
         ref={register({required: true})}
         placeholder="Digite seu Bairro"
       />
        {errors.neighborhood && <ErrorSpan>* Campo Obrigatório</ErrorSpan>}

        <Input 
         icon={GiModernCity}
         name="city" 
         ref={register({required: true})}
         placeholder="Digite sua cidade" 
         />
       {errors.city&& <ErrorSpan>* Campo Obrigatório</ErrorSpan>}

      <Input 
         icon={AiOutlineFieldNumber}
         name="StreetNumber" 
         ref={register({required: true})}
         placeholder="Digite o numero da residência"  
         />    
        {errors.streetNumber && <ErrorSpan>* Campo Obrigatório</ErrorSpan>}
      <Input 
         icon={BsCodeSlash}
         name="zipcode" 
         ref={register({required: true, minLength: 8, maxLength:8})}
         placeholder="Digite seu cep" 
         />     
        {errors.zipcode && <ErrorSpan>* Campo Obrigatóriom</ErrorSpan>}
        {errors.zipcode && errors.zipcode.type === "minLength" && <ErrorSpan>Digite os 8 dígitos do CEP sem 0 '-'</ErrorSpan> }
        {errors.zipcode && errors.zipcode.type === "maxLength" && <ErrorSpan>Digite apenas 8 dígitos do CEP sem o '-'</ErrorSpan> }

        
       <Button type="submit">Finalizar Cadastro</Button>
       <a href="https://api.whatsapp.com/send?phone=+558281446138" target="_blank"><span style={{color: '#fff'}}>Entrar em Contato</span></a>
     </form>

</Container>
  );
}

export default SignupAddressForm;