import React, { useEffect, useState } from 'react';
import { useIsOpenModal } from '../../hooks/modal';
import Modal from 'react-modal';
import './styles.css'


interface IModal {
    children: React.ReactNode;
    onCloseModal: ()=>void;
    isOpenModalProp?:boolean;

}
Modal.setAppElement('#root')

const ModalCustom: React.FC<IModal> = ({children, onCloseModal, isOpenModalProp}) => {
    const {isOpenModal, setIsOpenModal } = useIsOpenModal()

    return (
        <div className="container">
          <Modal
            isOpen={isOpenModalProp ?? isOpenModal as boolean}
            onAfterOpen={()=>{}}
            onRequestClose={onCloseModal}
            className="modal"
            contentLabel="Cadastro"
            overlayClassName="overlay"

            
          >
              {children}
              </Modal>
              </div>
  )
}

export default ModalCustom;