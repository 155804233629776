export interface SelectOptionsCustom {
  label: string;
  value: string;
}

export const partners = [
  { label: 'São Paulo - Capital', value: 'São Paulo - Capital' },
  { label: 'Fortaleza - CE', value: 'Fortaleza - CE' },
  { label: 'Rio de Janeiro - RJ', value: 'Rio de Janeiro - RJ' },
  { label: 'Brasília - DF', value: 'Brasília - DF' },
  { label: 'Manaus -AM', value: 'Manaus -AM' },
  { label: 'Porto Velho - RO', value: 'Porto Velho - RO' },
  { label: 'Belo Horizonte - MG', value: 'Belo Horizonte - MG' },
  { label: 'Curitiba - PR', value: 'Curitiba - PR' },
  { label: 'São Luís - MA', value: 'São Luís - MA' },
  { label: 'Teresina - PI', value: 'Teresina - PI' },
  { label: 'Boston - EUA', value: 'Boston - EUA' },
  { label: 'Recife - PE', value: 'Recife - PE' },
  { label: 'Vitória - ES', value: 'Vitória - ES' },
  { label: 'Ribeirão Preto - SP', value: 'Ribeirão Preto - SP' },
  { label: 'Niterói - RJ', value: 'Niterói - RJ' },
  { label: 'Florianópolis - SC', value: 'Florianópolis - SC' },
  { label: 'Natal - RN', value: 'Natal - RN' },
  { label: 'Campinas - SP', value: 'Campinas - SP' },
  { label: 'Porto Alegre - RS', value: 'Porto Alegre - RS' },
  { label: 'Belém - PA', value: 'Belém - PA' },
  { label: 'Uberlândia - MG', value: 'Uberlândia - MG' },
  { label: 'Luanda – AO', value: 'Luanda – AO' },
  { label: 'Maringá - PR', value: 'Maringá - PR' },
  { label: 'Goiânia – GO', value: 'Goiânia – GO' },
  { label: 'Palmas - TO', value: 'Palmas - TO' },
  { label: 'Santo André - SP', value: 'Santo André - SP' },
  { label: 'Maceió - AL', value: 'Maceió - AL' },
  { label: 'Campina Grande - PB', value: 'Campina Grande - PB' },
  { label: 'Orlando – EUA', value: 'Orlando – EUA' },
  { label: 'Joinville – SC', value: 'Joinville – SC' },
  { label: 'São José dos Campos – SP', value: 'São José dos Campos – SP' },
  { label: 'Lisboa – PT', value: 'Lisboa – PT' },
  { label: 'Salvador – BA', value: 'Salvador – BA' },
  { label: 'Campo Grande – MS', value: 'Campo Grande – MS' },
  { label: 'Aracaju - SE', value: 'Aracaju - SE' },
  { label: 'Blumenau – SC', value: 'Blumenau – SC' },
  { label: 'Juiz de Fora – MG', value: 'Juiz de Fora – MG' },
  { label: 'Foz do Iguaçu – PR', value: 'Foz do Iguaçu – PR' },
  { label: 'Chapecó - PR', value: 'Chapecó - PR' },
  { label: 'Faculdade Febracis Lorran', value: 'Faculdade Febracis Lorran' },
  { label: 'Faculdade Febracis  Yara', value: 'Faculdade Febracis  Yara' },
  { label: 'Manaus - AM Márcia Greid', value: 'Manaus - AM Márcia Greid' },
  { label: 'Rondonia - RO Márcia Greid', value: 'Rondonia - RO Márcia Greid' },
  { label: 'Goiania - GO', value: 'Goiania - GO' },
  { label: 'Fernando Lucas', value: 'Fernando Lucas' },
  {
    label: 'Brasilia - BR Luciane Martins',
    value: 'Brasilia - BR Luciane Martins',
  },
  { label: 'Curitiba - PR Heraldo', value: 'Curitiba - PR Heraldo' },
  { label: 'Maringá - PR Heraldo', value: 'Maringá - PR Heraldo' },
  { label: 'São Paulo - SP Atila', value: 'São Paulo - SP Atila' },
  { label: 'Ribeirao Preto - SP Atila', value: 'Ribeirao Preto - SP Atila' },
  { label: 'Ribeirao Preto - SP Zé', value: 'Ribeirao Preto - SP Zé' },
  {
    label: 'Ribeirao Preto - SP Tatiane',
    value: 'Ribeirao Preto - SP Tatiane',
  },
  { label: 'São Paulo - SP  Tatiane', value: 'São Paulo - SP  Tatiane' },
  { label: 'São Paulo - SP Zé ', value: 'São Paulo - SP Zé' },
  { label: 'Aracaju - SE Camila Lima', value: 'Aracaju - SE Camila Lima' },
  { label: 'Florianopolis - SC', value: 'Florianopolis - SC' },
  {
    label: 'Recife - PE Indiene e Viviane',
    value: 'Recife - PE Indiene e Viviane',
  },
  {
    label: 'Rio de Janeiro - RJ Carlos Rodrigues',
    value: 'Rio de Janeiro - RJ Carlos Rodrigues',
  },
  {
    label: 'Maceio - AL Sanyelle Ribeiro',
    value: 'Maceio - AL Sanyelle Ribeiro',
  },
  {
    label: 'Uberlandia - MG Ítalo de Oliveira',
    value: 'Uberlandia - MG Ítalo de Oliveira',
  },
  { label: 'Santa Catarina - SC', value: 'Santa Catarina - SC' },
  { label: 'Espirito Santo - SC', value: 'Espirito Santo - SC' },
  { label: 'Campo Grande - MS', value: 'Campo Grande - MS' },
  { label: 'São Luis - MA', value: 'São Luis - MA' },
  { label: 'Joinville - SC', value: 'Joinville - SC' },
  { label: 'Chapeco - SC', value: 'Chapeco - SC' },
  { label: 'Belém - PA', value: 'Belém - PA' },
  { label: 'Fortaleza - CE', value: 'Fortaleza - CE' },
];
