/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Container, Content, Logo, ContainerForm, InfoBuyer } from './styles';
import logoImg from '../../assets/images/nasa.png';
import logoyoung from '../../assets/images/logoyoung.png';
import { useFormComplete } from '../../hooks/form';
import SingleSelect from '../../components/select/select';
import PagarmeButton from '../../components/pagarme-button';
import { Order } from '../../context/Order';
import Input from '../../components/input';
import ModalCustom from '../../components/modal';
import Button from '../../components/button';
import CreditCardOptions, {
  PaymentCreditCard,
} from '../../components/credircard-options';
import { useAuth } from '../../hooks/auth/auth-hook';
import useAxios from '../../services/hooks/axios-hook';
import spaceNasa from '../../assets/images/launch.json'

import { disableBodyScroll } from 'body-scroll-lock';
import Loading from '../../components/loading';
import { Link, Navigate, useNavigate } from 'react-router-dom';


const Payment = () => {
  const [directorIndication, setDirectorIndication] = useState('no');
  const { isCompleteForms, setIsCompleteForms } = useFormComplete();
  const [orderData, setOrderData] = useState<Order>();
  const [partnerDescription, setPartnerDescription] = useState('');
  const [moreThanOndeCreditCard, setMoreThanOndeCreditCard] = useState(false);
  const [amountCreditcard, setAmountCreditcard] = useState(2);
  const { get,put } = useAxios();
  const {auth, setAuth} = useAuth();
  const currentElement = document.querySelector<any>('#root');
  const [loading, setLoading] = useState(false)
  const [somePaymentTrue, setSomePaymentTrue] = useState(false)
  const[partnerUpdated, setPartnerUpdated]= useState(false);

const navigate = useNavigate();
  useEffect(() => {
    (async ()=>{
      const authToken = JSON.parse(window.localStorage.getItem('auth') as string);
      const accessToken = authToken.access_token
      if (authToken.access_token) {
        setLoading(true)
         const dataOrder = await get('buyers',accessToken)
         if(dataOrder){
           const {order, ...rest}= dataOrder;
           const dataOrderTomapperLocal={...order, buyer:rest}
           window.localStorage.setItem('orderData',JSON.stringify(dataOrderTomapperLocal))
            const checkPayments= dataOrderTomapperLocal.payments.find((payment: PaymentCreditCard)=>payment.statusPayment);
            checkPayments && setSomePaymentTrue(true)
           setTimeout(() => {
            setOrderData(dataOrderTomapperLocal)
            setAuth(true);
            setLoading(false)

           }, 2500);
           }else{
            return <Link to='signup'/>
         }
      }
     

    })()
   
     
      disableBodyScroll(currentElement)

  }, []);

  const sendPartnerInfo=async ()=>{
    const authToken = JSON.parse(window.localStorage.getItem('auth') as string);
      const accessToken = authToken.access_token
       const order = await put(`orders/${orderData?.id}`, {partner: isCompleteForms.partner },accessToken )
       if(order){
        window.location.reload()
       }
       
      }
  return (
    <>
    {loading ? (<Loading image={spaceNasa}/>):(
    <Container>
      <Logo />
      <ContainerForm>
        <Content>
          <img
            src={logoyoung}
            alt="Nasa"
            width="75px"
            style={{ marginLeft: -300 }}
          />
          <img
            src={logoImg}
            alt="Nasa"
            width="150px"
            style={{ marginBottom: 30 }}
          />
          {auth ? (
            <InfoBuyer>
              <div style={{ marginBottom: 10 }}>
                <label>
                  Nome: <span>{orderData?.buyer?.name}</span>
                </label>
              </div>
              <div style={{ marginBottom: 10 }}>
                <label>
                  CPF: <span>{orderData?.buyer?.cpf}</span>
                </label>
              </div>
              <div style={{ marginBottom: 10 }}>
                <label>
                  E-Mail: <span>{orderData?.buyer?.email}</span>
                </label>
              </div>
              <div style={{ marginBottom: 10 }}>
                <label>
                  Plano: <span>{orderData?.plan}</span>
                </label>
              </div>
              {/* <div style={{ marginBottom: 10 }}>
                <label>
                  Valor Pago: <span>{orderData?.valuePayed}</span>
                </label>
              </div> */}
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gridGap: '10px',
                  listStyle: 'none',
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                {!somePaymentTrue ?(<div>
                  <label style={{ marginBottom: 20 }}>
                    {' '}
                    Pagar em Mais de 1 cartão{' '}
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="yes"
                      checked={moreThanOndeCreditCard}
                      onChange={() => setMoreThanOndeCreditCard(true)}
                    />
                    Sim!
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="no"
                      checked={!moreThanOndeCreditCard}
                      onChange={() => setMoreThanOndeCreditCard(false)}
                    />
                    Não!
                  </label>
                  {moreThanOndeCreditCard ? (
                    <>
                      <div style={{ display: 'flex', maxWidth: 55 }}>
                        <Input
                          value={amountCreditcard}
                          type="text"
                          placeholder="Máximo 3 mínimo 2"
                          onChange={(e) =>
                            setAmountCreditcard(Number(e.target.value))
                          }
                        />
                      </div>
                      <label style={{ fontSize: 14, color: 'red' }}>
                        * máximo 3 min 2
                      </label>

                      <Button
                        onClick={() =>
                          setIsCompleteForms({
                            ...isCompleteForms,
                            isCreditCardOptionShow: true,
                          })
                        }
                      >
                        Definir Valores
                      </Button>

                      <ModalCustom
                        onCloseModal={() => {}}
                        isOpenModalProp={isCompleteForms.isCreditCardOptionShow}
                      >
                        <CreditCardOptions
                          amountCreditCardPayments={amountCreditcard}
                        />
                      </ModalCustom>
                    </>
                  ) : null}
                </div>):null}
                {!orderData?.partner?(<div>
                  <label style={{ marginBottom: 20 }}>
                    {' '}
                    Foi indicador por alguém?{' '}
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="yes"
                      checked={directorIndication === 'yes'}
                      onChange={(e) => setDirectorIndication(e.target.value)}
                    />
                    Sim!
                  </label>
                  
                  <label>
                    <input
                      type="radio"
                      value="no"
                      checked={directorIndication === 'no'}
                      onChange={(e) => setDirectorIndication(e.target.value)}
                    />
                    Não!
                  </label>
                  {directorIndication === 'yes' ? (
                    <>
                    <div>
                      <SingleSelect />
                    </div>
                    <div>
                    <Button
                        onClick={() =>sendPartnerInfo()}
                      >
                        Confirmar 
                      </Button>
                    </div>
                      </>
                  ) : null}
                </div>):null}
              </div>
            </InfoBuyer>
          ):null}
          {
           (()=>{
             const array=[]
             const payments = orderData?.payments as PaymentCreditCard[]
             const length= payments?.length as Number
             
              for (let index = 0; index <  length; index++) {
                if(payments[index].amount>0){
                  const priceTransform = new Number(payments[index].amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                  array.push(
                    <PagarmeButton payed={payments[index].statusPayment} paymentId={payments[index].id} amountCurrency={priceTransform} amount={payments[index].amount} ></PagarmeButton>
                  )
                }
            }
            if(!length){
              const total = Number(orderData?.total)
              const paymentID=String(orderData?.payments[0].id)
              const isPayed=Boolean(orderData?.payments[0].statusPayment)
              const priceTransform = new Number(total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              return <PagarmeButton payed={isPayed} paymentId={paymentID} amountCurrency={priceTransform} amount={total}></PagarmeButton>

            }
            return array;  
            })()  
          }
        <a href="https://api.whatsapp.com/send?phone=+558281446138" target="_blank"><span style={{color: '#ddd5eb'}}>*Entrar em Contato*</span></a>

        </Content>

      </ContainerForm>
    </Container>
    )
        }
    </>
  );
};

export default Payment;
