import React, { InputHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons/lib';
import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ComponentType<IconBaseProps>;
}
const Input =  React.forwardRef(({ icon: Icon, ...rest}: InputProps, ref: string | ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined  ) => {
return( 
  <Container>
    {Icon && <Icon size={20} />}
    <input ref={ref} {...rest} />
  </Container>

);
});
export default Input;
